<template>
  <v-dialog v-model="show" max-width="500" persistent>
    <v-card>
      <v-card-title class="msaBlue white--text"> Attach Projects </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="mt-4">
            <AutocompleteWithList
              placeholder="Select project(s)"
              :items="projects"
              multiple
              @change="onSelect($event)"
              :key="reload"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="close()"> cancel </v-btn>
        <v-badge
          :value="selected.length"
          :content="selected.length"
          color="green"
          offset-x="15px"
          offset-y="10px"
        >
          <v-btn
            class="msaBlue white--text ml-4"
            @click="confirm()"
            :disabled="selected.length == 0"
          >
            confirm
          </v-btn>
        </v-badge>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AutocompleteWithList from '@/components/AutocompleteWithList.vue';
export default {
  name: 'EmployeeProjectList',
  components: {
    AutocompleteWithList,
  },
  data() {
    return {
      projects: [],
      selected: [],
      reload: false,
    };
  },
  props: {
    value: Boolean,
  },
  watch: {
    value: function (nv) {
      if (!nv) {
        this.projects = [];
        this.selected = [];
      } else {
        this.getAllProjects();
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    close() {
      this.reload = !this.reload;
      this.show = false;
    },
    getAllProjects() {
      let params = {
        organizationId: this.$store.getters.user.companyId,
        employeeId: this.$store.getters.selectedUser.id,
        loaderText: 'loading...',
      };
      this.$axios
        .post('get-available-projects-for-user?format=json', params)
        .then((response) => {
          this.projects = response.data;
        });
    },
    confirm() {
      let params = {
        userIds: [this.$route.params.id],
        projectIds: this.selected,
        loaderText: 'Attaching project...',
      };
      this.$axios
        .post('add-projects-to-employees?format=json', params)
        .then(() => {
          this.close();
          this.$emit('projectAdded');
        });
    },
    onSelect(event) {
      this.selected = JSON.parse(JSON.stringify(event));
    },
  },
};
</script>
