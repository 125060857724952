<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6">
        <SearchBar @search="search = $event" />
      </v-col>
      <v-col class="grow" align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="msaBlue white--text"
              v-on="on"
              @click="showProjectList = true"
            >
              <v-icon> mdi-tag-plus-outline </v-icon>
            </v-btn>
          </template>
          <span>Attach projects</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Attached Projects
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="attachedProjects"
            :server-items-length="attachedProjectCount"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :options.sync="options"
            :no-data-text="noDataMessage"
            :loading="isLoading"
            must-sort
            @update:options="loadProjects"
          >
            <template v-slot:body="{ items }" v-if="attachedProjectCount > 0">
              <v-slide-y-transition group tag="tbody">
                <template v-for="(item, index) in items">
                  <tr :key="item.id">
                    <td class="msaBlue--text">
                      <router-link
                        :to="{
                          name: 'CompanyProjects',
                        }"
                        class="text-decoration-none"
                      >
                        {{ item.name }}
                      </router-link>
                    </td>
                    <td align="center">
                      <v-btn
                        text
                        color="red"
                        @click="removeProject(item, index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-slide-y-transition>
            </template>
            <template v-else>
              <v-row>
                <v-col>
                  {{ noDataMessage }}
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <EmployeeProjectList v-model="showProjectList" @projectAdded="load" />
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import EmployeeProjectList from '@/components/EmployeeProjects/EmployeeProjectList.vue';
export default {
  name: 'UserProjectsPage',
  components: {
    EmployeeProjectList,
    SearchBar,
  },
  data() {
    return {
      attachedProjects: [],
      attachedProjectCount: 0,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      isLoading: false,
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          value: '',
          align: 'center',
          class: 'lightGrey',
          width: '60px',
          sortable: false,
        },
      ],
      search: '',
      showProjectList: false,
      noDataMessage: 'No results found',
    };
  },
  watch: {
    search() {
      this.load();
    },
  },
  methods: {
    loadProjects() {
      this.isLoading = true;
      const params = {
        employeeId: this.$route.params.id,
        search: this.search,
        options: this.options,
      };
      this.$axios
        .post('get-projects-by-user?format=json', params)
        .then((response) => {
          this.attachedProjects = response.data.items;
          this.attachedProjectCount = response.data.count;
          this.$store.commit('updateSelectedUser', {
            ...response.data.user,
            status: response.data.user.status == 'Active' ? 1 : 0,
          });
          this.isLoading = false;
        });
    },
    removeProject(project, index) {
      const params = {
        employeeId: this.$route.params.id,
        projectId: project.projectId,
        loaderText: 'Removing project...',
      };
      this.$axios
        .post('remove-project-from-user?format=json', params)
        .then(() => {
          this.attachedProjects.splice(index, 1);
          this.attachedProjectCount -= 1;
          this.isLoading = true;
          this.loadProjects();
        });
    },
    load() {
      this.options.page = 1;
      this.loadProjects();
    },
  },
};
</script>
